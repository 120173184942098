import { useEffect, useRef, useState } from 'react'
import {
  Stimulsoft,
  StiViewer,
  StiViewerOptions,
  StiOptions,
} from 'stimulsoft-dashboards-js/Scripts/stimulsoft.viewer'
import { Report, StimulsoftLicenseKey } from './Report/ReportDashboard'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import { request } from './state/api'
import FontLoader from './Report/FontLoader'
import { useConfig } from './state/config'
import { useInstance } from './state/auth/currentInstance'
import { useClient } from './state/auth/currentClient'

export const Overview = () => {
  const [dashboard, setDashboard] = useState<Report | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [height, setHeight] = useState(window.innerHeight)
  const viewerRef = useRef<HTMLDivElement>(null)
  const reportsUrl = useConfig(store => store.reportsUrl)

  const gsr_inst = useInstance(state => state.gsr_inst)
  const gsr_client = useClient(state => state.gsr_client)

  // Get dashboard
  useEffect(() => {
    const getData = async () => {
      try {
        const { data, response } = await request(
          `/reports/overview`,
          {
            method: 'get',
            queryParameters: {
              gsr_inst: gsr_inst,
              gsr_client: gsr_client,
            },
          },
          true // Bypass global error handling
        )

        console.log('response', response)

        if (response.status === 200) {
          console.log('ok response')
          setDashboard(data)
        } else {
          console.error('Failed to fetch dashboard.')
          setDashboard(null)
        }
      } catch (err) {
        console.error('Error fetching dashboard: ', err)
      }
      setIsLoading(false)
    }
    getData()
  }, [gsr_inst])

  // Handle Window Resize
  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    // Initialize and render the viewer when the dashboard is fetched
    if (dashboard && viewerRef.current) {
      // Stimulsoft activation
      Stimulsoft.Base.StiLicense.key = StimulsoftLicenseKey

      const options = new StiViewerOptions()
      options.toolbar.visible = false
      options.appearance.fullScreenMode = false
      options.appearance.allowMobileMode = false
      options.height = `${height - 100}px`

      // Configure Stimulsoft WebServer options
      StiOptions.WebServer.encryptData = false
      StiOptions.WebServer.checkDataAdaptersVersion = false
      StiOptions.WebServer.url = `${reportsUrl}:9615`

      const viewer = new StiViewer(options, 'StiViewer', false)
      const report = Stimulsoft.Report.StiReport.createNewDashboard()
      report.load(dashboard.template)

      viewer.report = report
      viewer.renderHtml(viewerRef.current?.id)
    }
  }, [dashboard, height])
  return (
    <>
      {!dashboard ? (
        <div
          style={{
            display: 'flex',
            color: 'white',
            width: '100%',
            backgroundColor: '#555',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 auto',
            height: '100%',
          }}>
          <DashboardOutlinedIcon /> &nbsp;
          {isLoading ? 'Dashboard is Loading ...' : 'No overview dashboard defined'}
        </div>
      ) : (
        <>
          <FontLoader Stimulsoft={Stimulsoft} />
          <div id="viewerContent" ref={viewerRef}></div>
        </>
      )}
    </>
  )
}
